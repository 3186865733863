export const environment = {
  production: true,
  config: {
    feedRoot: 'https://api.bo.ticket.run.airweb.fr/feed/',
    tapRoot: 'https://production-api-kcvqfuvlla-ew.a.run.app/',
    abtRoot: 'https://api.validators.ticket.airweb.fr',
    exportRoot: 'https://eu-export-ack5cv4rnq-ew.a.run.app/',
    mapApiKey: 'AIzaSyBBqcl8KrnY5PJetpLkmNT6SwxVdZwmDtQ',
    defaultCoords: '46.71109,1.7191036',
    loopedInApiBaseUrl:
      'https://europe-west9-ticket-cms.cloudfunctions.net/eu-production-looped-in',
    loopedInWorkspaceId: '626a4cf7545a45002a3c5f02',
    clarityCode: 'd0s2llzfwm',
    statsRoot: 'https://analytics.airwebpass.com/',
    assetsRoot: 'https://eu.assets.ticket.airweb.fr/',
    configURL: 'https://storage.googleapis.com/airweb-ticket-public-europe/back-office/config.json',

    adminlabsURL: 'https://europe-west9-ticket-cms.cloudfunctions.net/eu-production-admin-labs',
    adminlabsBackOfficeComponentIds: [
      '960e8d2b-1a1f-11ed-ac0f-000c29d658bc',
      '46060d83-1a1f-11ed-ac0f-000c29d658bc',
      '8c6ae7a8-1a1f-11ed-ac0f-000c29d658bc',
      'c9a3059d-1c71-11eb-ac0f-000c29d658bc',
      '58f70937-edf3-11ea-ac0f-000c29d658bc',
      '555bc964-2b1f-11eb-ac0f-000c29d658bc',
      '5c638f3c-edf3-11ea-ac0f-000c29d658bc',
      '9356ed0e-2b20-11eb-ac0f-000c29d658bc',
      'ad9f396b-22ab-11eb-ac0f-000c29d658bc',
      '376398d1-22b0-11eb-ac0f-000c29d658bc',
      '543f8e0a-edf3-11ea-ac0f-000c29d658bc',
      '3d45399d-1a1f-11ed-ac0f-000c29d658bc',
      '79eb74b3-1a1f-11ed-ac0f-000c29d658bc',
      'c253d2aa-1c71-11eb-ac0f-000c29d658bc',
      '482d9348-edf3-11ea-ac0f-000c29d658bc',
      '844eebf6-22ab-11eb-ac0f-000c29d658bc',
      '37314b6b-6f36-11ee-ac0f-000c29d658bc',
      '9105d282-1c4c-11eb-ac0f-000c29d658bc',
      '4ddd7a70-edf3-11ea-ac0f-000c29d658bc',
      '593e5428-ff10-11ea-ac0f-000c29d658bc',
      '3c2b825f-edf3-11ea-ac0f-000c29d658bc',
      'b75e5fc8-1c71-11eb-ac0f-000c29d658bc',
      '64312ffe-2b20-11eb-ac0f-000c29d658bc',
      '82d49049-1a1f-11ed-ac0f-000c29d658bc',
      '538eba7c-ff10-11ea-ac0f-000c29d658bc',
      '8c44ded8-22ab-11eb-ac0f-000c29d658bc',
    ],
    airwebStatusUrl: 'https://status.airweb.fr/',
    newAuthenticationUrl: 'https://eu.connect.airwebpass.com/auth',
    authFactorTutoPath: 'https://assets.ticket.airweb.dev/administration/documentation/6e4fd5ae-3dd5-11ef-bcc2-2abc636a44d3.pdf',
    maintenanceUrlList: [
      'https://primary.upkeep.run.airweb.fr/api/alerts',
      'https://secondary.upkeep.airwebpass.com/api/alerts',
    ],
  },
};
